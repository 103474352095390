import React from "react"
import "./style.scss"
import { StaticImage } from "gatsby-plugin-image"

const LifemartPartnership = () => {
  return (
    <div className="lifemart-partnership">
      <div className="lifemart-partnership-container">
        <p>
          Plus, receive an <span>exclusive</span> 15% off every monthly subscription order!
        </p>
        <span>
          In Partnership with
        </span>
        <StaticImage src={"../../../images/Placeholder/LifeMart.png"} alt="" />
      </div>
    </div>
  )
}

export default LifemartPartnership
