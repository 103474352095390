import React, { useState, useContext } from "react"
import { navigate } from "gatsby"

import "./style.scss"
import { subscriptionProducts } from "./mockedData"
import { Link } from "gatsby"
import LensCompare from "../../LensCompare"
import CustomerContext from "../../../context/customerContext"
import WebpImage from '../../WebpImage'
import CartContext from "../../../context/cartContext"

const LifemartSubscriptions = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { setLifemartRx } = useContext(CustomerContext)

  const closePopup = () => {
    setLifemartRx(false)
    setIsPopupOpen(false)
  }
  const openPopup = () => {
    setLifemartRx(true)
    setIsPopupOpen(true)
  }

  return (
    <div className="lifemart-subscriptions-section">
      <div className="lifemart-subscriptions">
        {subscriptionProducts.map(product => {
          return (
            <div className="subscription">
              <WebpImage
                className="product-image"
                fileName={product.image}
                alt={product.alt}
              />
              <h3 className="product-title">{product.title}</h3>
              <p className="product-description">{product.description}</p>
              <p className="product-price">
                <span className="product-price-crossed">{product.price}</span>
                <span className="product-price-sale">{` ${product.salePrice}`}</span>
                <br></br>
                {product.priceDescription}
              </p>
              <CtaButton product={product} className='mobile' setLifemartRx={setLifemartRx} />
            </div>
          )
        })}
      </div>
      <div className="lifemart-subscriptions desktop-buttons">
        {subscriptionProducts.map(product => {
          return (
            <CtaButton product={product} className='desktop' setLifemartRx={setLifemartRx} />
          )
        })}
      </div>
      <p className="lifemart-compare" onClick={openPopup}>
        Compare Our Contact Lenses
      </p>
      <LensCompare isPopup closePopup={closePopup} isPopupOpen={isPopupOpen} />
    </div>
  )
}

export const CtaButton = ({ product, className, setLifemartRx }) => {
  const { isLensesInCart } = useContext(CartContext)

  return (
    <div className={`subscription-button-container ${className}`}>
      <Link
        to={isLensesInCart ? "/lifemart" : product.link}
        className="btn btn-primary cta-btn subscription-button"
      >
        Shop {product.title}
      </Link>
    </div>
  )
}

export default LifemartSubscriptions
