import React, { useContext, useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import "./style.scss"
import CustomerContext from "../../../context/customerContext"
import CartContext from "../../../context/cartContext"
import { useWindowWidth } from '../../../services/hooks'

const LifemartHomeCta = () => {
  const { isLensesInCart } = useContext(CartContext)
  const { setLifemartRx } = useContext(CustomerContext)
  const [isMobile, setIsMobile] = useState()

  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 600)
  }, [windowWidth])

  const handleClick = e => {
    if (!isLensesInCart) {
      setLifemartRx(true)
    }
  }

  return (
    <div className="lifemart-home-cta">
      <div className="grid-section">
        <h2>Over 400 million lenses sold.</h2>
        <p className="cta-text">
          You can stop shopping around. {!isMobile && <br/>}We’ll be your single point of contacts.
        </p>
        <Link to={isLensesInCart ? "/lifemart" : "/contact-lenses/classic-by-hubble-lenses"} onClick={handleClick} className="btn btn-primary cta-btn">
          Start Your Subscription
        </Link>
      </div>
    </div>
  )
}

export default LifemartHomeCta
