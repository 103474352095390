import { Link, navigate } from "gatsby"
import React, { useContext } from "react"
import "./style.scss"
import Banner from "../../Banner"
import CustomerContext from "../../../context/customerContext"
import CartContext from "../../../context/cartContext"

const LifemartHero = () => {
  const { isLensesInCart } = useContext(CartContext)
  const { setLifemartRx } = useContext(CustomerContext)
  
  const handleClick = e => {
    // e.preventDefault()
    if (!isLensesInCart) {
      setLifemartRx(true)
    }
    // navigate(e.target.href)
  }

  return (
    <div className="lifemart-hero">
      <Banner
        bannerText={"Get 15 % OFF Your Hubble Subscription"}
        bannerBG={"purple"}
      />
      <div className="grid-section">
        <h1 className="gradient-title">Hubble Contacts</h1>
        <p className="discount">Get 15% OFF Your Monthly Subscription</p>
        <p className="description">
          We’re happy to extend this exclusive offer on our convenient and affordable daily contact lenses — in partnership with LifeMart.
        </p>
        <Link
          to={isLensesInCart ? "/lifemart" : "/contact-lenses/classic-by-hubble-lenses"}
          onClick={handleClick}
          className="btn btn-primary cta-btn"
        >
          Start Your Subscription
        </Link>
      </div>
    </div>
  )
}

export default LifemartHero
