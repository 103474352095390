export const reviews = {
  lifemart: [
    {
      review_copy:
      "Received my first 2 boxes of Hubble contacts yesterday & I absolutely LOVE the contacts, so far. Very comfortable & moist all day. I will definitely keep them coming! Thank you Hubble! I will be forever a subscriber.",
      reviewer: "Charlie K.",
    },
    {
      review_copy:
        "This is literally the only brand of contacts that works for me. I've tried all of the major brands and was ready to give up. I love my Hubble Contacts. <3",
      reviewer: "Anna H.",
    },
    {
      review_copy:
        "OMG when I put them on I was extremely impressed. I'm in love, I cannot even remotely tell they are in. I will be a forever subscriber. Thank you Hubble!",
      reviewer: "Heather P.",
    },
    {
      review_copy:
        "Thanks to @hubblecontacts for the SUPER SIMPLE process! I will be a return customer for sure!",
      reviewer: "Ashley",
    },
  ],
}
