import React, { useEffect } from "react"
import Layout from "../components/layout"

import LifemartHero from "../components/Lifemart/LifemartHero"
import LifemartMissionStatement from "../components/Lifemart/LifemartMissionStatement"
import LifemartPartnership from "../components/Lifemart/LifemartPartnership"
import LifemartSubscriptions from "../components/Lifemart/LifemartSubscriptions"
import LifemartValuePropsSection from "../components/Lifemart/LifemartValuePropsSection"
import LifemartHomeCta from "../components/Lifemart/LifemartHomeCta"
import LifemartReviewCarousel from "../components/Lifemart/LifemartReviewCarousel"
import LifemartVideoModule from "../components/Lifemart/LifemartVideoModule"
import LifemartGlasses from "../components/Lifemart/LifemartGlasses"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/pages/military-page.scss"

const LifemartPage = () => {
  useEffect(() => {
    localStorage.setItem("isLifemart", true)
  }, [])

  return (
    <Layout className="military-page" isLogoHeader isReducedFooter>
      <LifemartHero />
      <LifemartMissionStatement />
      <LifemartSubscriptions />
      <LifemartPartnership />
      <LifemartGlasses />
      <LifemartVideoModule
        video={"https://www.youtube.com/embed/sB7f8cYcvyg"}
        alt_text="Woman unboxes her Hubble contacts"
      />
      <LifemartValuePropsSection />
      <LifemartHomeCta />
      <LifemartReviewCarousel color="green" page="lifemart" />
    </Layout>
  )
}

export default LifemartPage
